
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationGitlabAuthorization'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  loading = false
  error = ''
  gitlabAuthorizationId = ''
  groups: { id: number, name: string }[] | null = null

  get code () {
    return this.$queryValue('code')
  }

  get state () {
    return decodeURIComponent(this.$queryValue('state') || '')
  }

  get organizationId () {
    return this.state?.split('_')[0]
  }

  get redirectPath () {
    return this.state?.split('_')[1] || '/'
  }

  mounted () {
    analytics.organizationGitlabAuthenticationScreen.track(this, {
      organizationId: [this.organizationId]
    })

    this.error = this.$queryValue('error') || ''
    if (!this.error) {
      this.auth()
    }
  }

  async auth () {
    try {
      if (this.loading) {
        return
      }
      this.loading = true

      if (!this.code) {
        throw new Error('GitLab code was not found')
      }
      if (!this.organizationId) {
        throw new Error('Organization id was not found')
      }

      const gitlabAuthorization = await this.organizationModule.gitlabAuthorizationCreate({
        code: this.code,
        organizationId: this.organizationId
      })

      this.gitlabAuthorizationId = gitlabAuthorization.id

      analytics.organizationGitlabAuthorizationCreate.track(this, {
        organizationId: [this.organizationId]
      })

      this.groups = await this.organizationModule.gitlabAuthorizationAvailableGroups({
        gitlabAuthorizationId: this.gitlabAuthorizationId,
        organizationId: this.organizationId
      })
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    } finally {
      this.loading = false
    }
  }

  async updateAccount ({ id, name }: { id: number, name: string }) {
    try {
      if (this.loading) {
        return
      }
      this.loading = true

      await this.organizationModule.gitlabAuthorizationUpdate({
        gitlabAuthorizationId: this.gitlabAuthorizationId,
        organizationId: this.organizationId,
        update: {
          groupId: id
        }
      })

      analytics.organizationGitlabAuthorizationUpdate.track(this, {
        organizationGitlabGroupName: name,
        organizationId: [this.organizationId]
      })

      this.$router.push(this.redirectPath)
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    }
  }
}
